<template>
  <li id="Collection">
    <a @click="$emit('onClickCard', id)">
      <div class="course-card episode-card">
        <div class="course-card-thumbnail ">
          <span class="item-tag">{{counter}} içerik</span>
          <img :src="image">
          <span class="play-button-trigger collection"></span>
        </div>
        <div class="course-card-body">
          <h4 class="mb-0">{{title}} </h4>
          <span><i class="uil-calendar-alt"></i> {{ collectionCreatedAt }}</span>
        </div>
      </div>
    </a>
  </li>


</template>

<script>
import moment from "moment"
import {MOMENT_DATE_FORMAT_FOR_KT} from "@/core/config/constant";
export default {
  name: "Collection",
  props:{
    id: {
      required: false,
      default: 1,
    },
    title: {
      required: true,
    },
    image: {
      required: true,
    },
    date: {
      required: true,
    },
    counter: {
      required: true,
    }
  },
  computed: {
    collectionCreatedAt() {
      return moment(this.date).format(MOMENT_DATE_FORMAT_FOR_KT)
    }
  }
}
</script>


import ApiService from "@/core/services/api.service";
import { responseError } from '@/core/mixin/shared'
import querystring from "querystring";

export const MODULE_NAME = 'SEARCHING';
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `GET_ITEMS`;


const state = {
    items: [],
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.item = null;
    },
};

const actions = {
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR,null);
                return {status: true, data: response.data};
            })
            .catch((error) => {
                 context.commit(SET_ERROR, error);
                context.commit(SET_LOADING, false);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
<template>
  <div class="uk-card-default uk-card-small rounded mt-5" v-if="recent_hashTag.length">
    <div class="section-header-left">
      <h4 class="uk-text-truncate text-left p-3"><i class="uil-label-alt"></i> {{$t('home.Popular_tage')}}</h4>
    </div>

    <div class="uk-grid-small p-3 mb-3" uk-grid>
      <div class="uk-width-expand">

        <p class="hashtag-cloud mb-0 uk-text-small">

                <template v-for="(tag, index) in recent_hashTag">
                  <tags
                    v-if="recent_hashTag.length"
                    :id="tag.id"
                    :title="tag.name"
                    @onClickTag="handleOnClickTag"
                >
                  </tags>
                </template>

        </p>
      </div>
    </div>

  </div>


</template>

<script>
import Tags from "@/view/components/search/Tags";
import {
  GET_ITEMS,
} from "@/core/services/store/REST.module";
export default {
  name: "PopularTags",
  components:{
    Tags
  },
  data(){
     return{
       recentHashTagUrl: "api/recent_tag",
       recent_hashTag:[]
       }
  },
  props:{
    search: {
      required: false,
      default: '',
    }
  },
  methods:{
    handleOnClickTag(payload) {
      let index = this.recent_hashTag.map(function(e) { return e.id; }).indexOf(payload);
      let value = this.recent_hashTag[index].name;

      this.$emit('updateSearch', value);
    },
    searchRecentTags(){
    let self = this;
    this.$store
      .dispatch(GET_ITEMS, {
        url: self.recentHashTagUrl,
        filters:{
          like: this.search
        },
        acceptPromise: false,
      })
      .then((result) => {
        if (result.status) {
          self.recent_hashTag = result.data;
        }
      });
    }
  },
  mounted(){
    this.searchRecentTags();
  },
  watch:{
    search(val){
      this.searchRecentTags()
    }
  }
}
</script>


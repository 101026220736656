<template>
 <div>
   <div class="uk-grid-small p-4" uk-grid>
     <div class="uk-width-1-5@m">
       <a href="javascript:void(0)" @click="handleOnClick(id)"><img :src="image" class="rounded" height="200" alt=""></a>
     </div>
     <div class="uk-width-expand">
       <a  href="javascript:void(0)" @click="handleOnClick(id)"><h5 class="mb-2"> {{title}}</h5></a>
       <p class="uk-text-small mb-2"> {{subTitle}}</p>
       <p class="mb-0 uk-text-small mt-3">
         <template v-for="(tag, index) in hashtags">
                  <tags
                    v-if="hashtags.length"
                    :id="tag.id"
                    :title="tag.name"
                    @onClickTag="handleOnClickTag"
                  >
                  </tags>
          </template>
       </p>
     </div>
   </div>
   <hr class="m-0">
 </div>
</template>

<script>
import Tags from "@/view/components/search/Tags";
export default {
  name: "SearchingResultItem",
  components:{
    Tags
  },
  props:{
    id:{
        required:true
    },
    title: {
      required: true,
    },
    image: {
      required: true,
    },
    subTitle: {
      required: true,
    },
    hashtags: {
      required:false,
      default:[]
    }
  },
  methods:{
    handleOnClick(id){
      this.$emit('click',id)
    },
    handleOnClickTag(payload) {
    },
  }
}
</script>


<template>

<div>
  <div class="uk-card rounded mb-4">
    <a @click="handleClick(id)">
      <div class="uk-card-header">
        <div class="uk-grid-small uk-flex-middle" uk-grid>
          <div class="uk-width-auto">
            <img class="uk-border-circle" width="40" height="40"
                 :src="image">
          </div>
          <div class="uk-width-expand">
            <h5 class="h6 mt-0 mb-0">{{name}}</h5>
            <p class="uk-text-meta uk-margin-remove-top"><time
                datetime="2016-04-01T19:00">@{{nameTag}}</time></p>
          </div>
          <div class="uk-width-auto">
            <div class="text-muted h7 mb-2"> <i class="uil-clock"></i>{{time}}</div>
          </div>
        </div>
      </div>
      <div class="uk-card-body">
        <p>{{description}} </p>
      </div>
    </a>
  </div>
  <hr class="m-0">
</div>


</template>

<script>
export default {
  name: "WallItem",
  props:{

    nameTag: {
      required: false,
    },
    id: {
      required: true,
    },
    name: {
      required: true,
    },
    description: {
      required: true,
    },
    image: {
      required: true,
    },
    time: {
      required: true,
    },

  },
  methods:{
    handleClick(id){
      this.$emit('click',id)
    }
  }

}
</script>

